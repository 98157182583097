import './Policy.scss';


export default function Policy() {
    return (
        <>
        <div className="page-layout">
            <div className="main-content">
                <h1>Privacy Policy</h1>
                    <p><strong>Last updated: 16 Jun 2024</strong></p>
                    <p>
                        Welcome to 91GAMES - Free to Play Slots Games. 
                        Your privacy is important to us. 
                        This Privacy Policy explains how we handle your information when you use our web application. 
                        Please read this policy carefully to understand our practices regarding your data.
                        This website is intended for users aged 18 and above only.
                    </p>
                    
                    <h2>1. Information We Collect</h2>
                    <p>
                        We do not collect any personal information from our users. 
                        Our game is designed to be enjoyed without the need for you to provide any personal data.
                    </p>
                    
                    <p>While we do not collect personal information, we may collect non-personal information that cannot be used to identify you. This may include:</p>
                    <ul>
                        <li><strong>Usage Data:</strong> Information about how the app is accessed and used, such as your gameplay behavior, in-game achievements, and preferences.</li>
                        <li><strong>Device Information:</strong> Technical information about the device you use to access our app, such as your device type, operating system, and app version.</li>
                    </ul>
                    
                    <h2>3. How We Use Non-Personal Information</h2>
                    <p>We use the non-personal information collected to:</p>
                    <ul>
                        <li><strong>Improve our Services:</strong> Understand how users interact with our app to enhance the user experience.</li>
                        <li><strong>Maintain and Update the App:</strong> Ensure the app runs smoothly and update it to improve functionality and security.</li>
                        <li><strong>Analyze Trends:</strong> Analyze usage trends to help us develop new features and content that our users will enjoy.</li>
                    </ul>
                    
                    <h2>4. Data Security</h2>
                    <p>
                        We are committed to ensuring that your information is secure. 
                        We use industry-standard security measures to protect against the loss, misuse, 
                        and alteration of the information under our control.
                    </p>
                    
                    <h2>5. Third-Party Services</h2>
                    <p>
                        We may employ third-party companies and individuals to facilitate our service, 
                        provide the service on our behalf, perform service-related tasks, 
                        or assist us in analyzing how our service is used. 
                        These third parties have access to your non-personal information only to perform 
                        these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
                    </p>
                    
                    <h2>6. Children's Privacy</h2>
                    <p>
                        Our app is not intended for use by anyone under the age of 18. 
                        We do not knowingly collect any information from individuals under 18. 
                        If we become aware that we have inadvertently collected information from someone under 18, 
                        we will take steps to delete such information as soon as possible.
                    </p>
                    
                    <h2>7. Changes to This Privacy Policy</h2>
                    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
            </div>
        </div>  
        </>
    )
}
