import { faCrown, faUserCircle, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Header.scss';
import { useSelector } from 'react-redux';
import { useEffect, useContext } from 'react';
import { SocketContext } from '../../context/socket';
import store from '../../store';
import lobbySlice from '../../lobbySlice';

const Header = (props) => {
  const loggedIn = useSelector((state) => state.lobby.loggedIn);
  const username = useSelector((state) => state.lobby.username);
  const balance = useSelector((state) => state.lobby.balance);

  const socket = useContext(SocketContext);

  useEffect(() => {
    socket.emit('balance', {
      key: localStorage.getItem('key'),
    });

    socket.on('balance', (balance) => {
      store.dispatch(lobbySlice.actions.updateBalance(balance));
    });
  }, []);

  return (
    <div className="Header">
      <div className="brand">
        <a className="link" href="/">
          <FontAwesomeIcon icon={faCrown} size="2x" className="logo"></FontAwesomeIcon>
          <span className="name">91GAMES</span>
        </a> 
      </div>

      <div className={`menu ${!loggedIn ? 'd-none' : ''}`}>
        <div className="account">
          <button className="btn-toggle-account-menu">
            <FontAwesomeIcon icon={faUserCircle} size="2xl"></FontAwesomeIcon>
            <span>{username}</span>
          </button>
        </div>

        <div className="dropdown-menu">
          <button className="btn-settings">
            <FontAwesomeIcon icon={faCircleInfo} size="2xl"></FontAwesomeIcon>
          </button>
          <div className="sub-menu">
            <a href="/about" className="btn-toggle-account-menu">
                <span>About</span>
            </a>
            <a href="/policy" className="btn-toggle-account-menu">
                <span>Privacy Policy</span>
            </a>
          </div>
        </div>
       

       
      </div>

      <div className={`balance ${!loggedIn ? 'd-none' : ''}`}>
        <span className="label">Balance</span>
        <span className="value">€{balance.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
      </div>
    </div>
  );
}

export default Header;
