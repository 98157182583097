import './App.scss';
import Header from './features/header/Header';
import {
  BrowserRouter, Route, Routes
} from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Game from './features/game/Game';
import GameList from './features/game-list/GameList';
import { Fragment } from 'react';
import Policy from './features/policy/Policy';
import About from './features/about/About';
import Club from './features/club/Club';

function App() {
  return (
    <HelmetProvider>
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Fragment>
            <Helmet>
              <script type="text/javascript">
              {`
              
              `}
              </script>
            </Helmet>
            <Header></Header>
            <GameList></GameList>
          </Fragment>}></Route>
          <Route path="/play/:gameId" element={<Game></Game>}></Route>
          <Route path="/about" element={<Fragment><Header></Header> <About /></Fragment>} />
          <Route path="/policy" element={<Fragment><Header></Header> <Policy /></Fragment>} />
          <Route path="/club" element={<Fragment><Club /></Fragment>} />
        </Routes>
      </BrowserRouter>
    </div>
    </HelmetProvider>
  );
}

export default App;
