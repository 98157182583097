import './Club.scss';


export default function Club() {
    return (
        <>
        <div className="club-page-layout">
            <div className="main-content">
                <img src="https://cdn-b.heylink.me/media/users/avatars/9fe8d42c12434c9fbc4008e8f4b0e88a.webp" alt="logo" className="main-logo"/>
                <h1 className='main-title'>Official Registration</h1>
                <div className="links-section">
                   
                </div>
            </div>
        </div>  
        </>
    )
}