import './About.scss';


export default function About() {
    return (
        <>
        <div className="page-layout">
            <div className="main-content">
                <h1>About Us</h1>
                    <p>
                        Welcome to 91GAMES, the ultimate destination for free-to-play casino games! 
                        Our mission is to bring the thrill and excitement of casino gaming to everyone, 
                        without any of the financial risk. Whether you're a seasoned player or a newcomer to the world of casino games,
                         91GAMES offers a fun and engaging experience for all.
                    </p>

                    <h2>Who We Are</h2>
                    <p>At 91GAMES, we are a passionate team of gaming enthusiasts and developers dedicated to creating high-quality, 
                        entertaining, and immersive casino games. 
                        Our goal is to provide a platform where players can enjoy the excitement of casino gaming in a safe and 
                        responsible environment.
                    </p>

                    <h2>What We Offer</h2>
                    <h3>A Wide Variety of Games</h3>
                    <p>From classic slots and poker to blackjack and roulette, 
                        91GAMES offers a diverse selection of casino games to suit every taste.
                         Our games are designed with stunning graphics, 
                         realistic sound effects, and smooth gameplay to ensure an enjoyable experience.
                    </p>

                    <h3>Play for Free</h3>
                    <p>
                        All our games are completely free to play. 
                        There are no deposits, no real money transactions, and no hidden fees. 
                        We believe in providing a fun and accessible gaming experience for everyone.
                    </p>

                    <h3>Regular Updates</h3>
                    <p>
                        We are constantly updating our platform with new games and features to 
                        keep things fresh and exciting. Our team is always working on innovative 
                        ways to enhance your gaming experience.
                    </p>

                    <h2>Our Commitment to Responsible Gaming</h2>
                    <p>
                        At 91GAMES, we are committed to promoting responsible gaming. 
                        Our platform is designed for entertainment purposes only, 
                        and we encourage all players to play responsibly. 
                        If you ever feel that your gaming is becoming more than just a fun pastime, 
                        we provide resources and support to help you stay in control.
                    </p>

                    <h2>Join Our Community</h2>
                    <p>
                        Join a vibrant community of players from around the world. Share your achievements, 
                        participate in challenges, and enjoy friendly competition. 
                        Follow us on social media to stay updated on the latest news, events, and promotions.
                    </p>

                    <h2>Contact Us</h2>
                    <p>
                        We love hearing from our players! 
                        If you have any questions, feedback, or suggestions, please get in touch with us. 
                        Our dedicated support team is here to assist you.
                    </p>

            <div class="contact">
                <p><strong>Email:</strong> <a href="mailto:support@91games.live">support@91games.live</a></p>
                <p><strong>Phone:</strong> +91 74530 80687</p>
            </div>
            </div>
        </div>  
        </>
    )
}