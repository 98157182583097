import * as PIXI from 'pixi.js';
import anime from 'animejs';

class Reel {
  constructor({
    positions,
    spinValues,
    speed,
    useBlurredSymbols,
    bounceDepthPerc,
    bounceDuration,
    symbolMargin,
    maskPaddingX,
    maskPaddingY,
  }) {
    this.positions = positions;
    this.values = [];
    this._spinValues = spinValues.slice();
    this.spinValues = spinValues.slice();
    this.stopValues = [];
    this.symbols = [];
    this.container = new PIXI.Container();
    this.mask = new PIXI.Graphics();
    this.offset = 0;
    this.rolling = false;
    this.stopping = false;
    this.symbolMargin = symbolMargin;
    this.speed = speed;
    this.bounceDepthPerc = bounceDepthPerc;
    this.bounceDuration = bounceDuration;
    this.stopFns = [];
    this.startFns = [];
    this.maskPaddingX = maskPaddingX || 0;
    this.maskPaddingY = maskPaddingY || 0;
    this.useBlurredSymbols = useBlurredSymbols || false;

    this.container.mask = this.mask;

    for (let i = 0; i < positions + 1; i++) {
      const symbol = new PIXI.Sprite(PIXI.Texture.EMPTY);
      this.container.addChild(symbol);
      this.symbols.push(symbol);
    }
  }

  render() {
    const m = this.mask;
    m.x = this.container.x;
    m.y = this.container.y;
    m.clear();
    m.beginFill(0x000000);
    m.drawRect(0 - this.maskPaddingX, 0 - this.maskPaddingY, this.symbols[0].width + (this.maskPaddingX * 2), ((this.symbols[0].height + this.symbolMargin + this.maskPaddingY) * this.positions) - this.symbolMargin);
    m.endFill();

    this.symbols.forEach((symbol, i) => {
      symbol.anchor.set(0.5, 0.5);

      if (this.values[i]) {
        symbol.texture = PIXI.Texture.from(`symbol-${this.values[i]}${this.rolling && this.useBlurredSymbols ? '-blurred' : ''}`);
      } else {
        symbol.texture = PIXI.Texture.EMPTY;
      }

      symbol.x = symbol.width / 2;
      symbol.y = ((symbol.height + this.symbolMargin) * (i - 1)) + (this.offset);
      symbol.y += symbol.height / 2;
    });

    if (this.rolling) {
      this.offset += this.symbols[0].height * this.speed;

      if (this.offset >= this.symbols[0].height) {
        this.offset = 0;
        if (!isNaN(parseInt(this.stopping, 10))) {
          this.values.unshift(this.stopValues.pop());
          this.stopping++;
        } else {
          this.values.unshift(this._spinValues.pop());

          if (this._spinValues.length === 0) {
            this._spinValues = this.spinValues.slice();
          }
        }
        this.values.splice(-1, 1);
      }

      if (this.stopping === this.positions + 1) {
        this.rolling = false;
        this.stopping++;
        const o = { offset: this.symbols[0].height * this.bounceDepthPerc };
        this.offset = o.offset;
        anime({
          targets: o,
          offset: 0,
          round: 1,
          duration: this.bounceDuration,
          easing: 'easeOutQuint',
          update: () => {
            this.offset = o.offset;
          },
          complete: () => {
            this.stopping = false;
            this.stopFns.forEach((fn, i) => {
              if (fn.once) {
                this.stopFns.splice(i--, 1);
              }
              fn();
            });
          },
        });
      }
    }
  }

  roll() {
    if (!this.rolling && this.stopping === false) {
      this.rolling = true;
      this.startFns.forEach((fn, i) => {
        if (fn.once) {
          this.startFns.splice(i--, 1);
        }
        fn();
      });
    }
  }

  stop() {
    if (this.rolling && this.stopping === false) {
      this.stopping = 0;
    }
  }

  onceStop(fn) {
    fn.once = true;
    this.stopFns.push(fn);
  }

  onStop(fn) {
    this.stopFns.push(fn);
  }

  onceStart(fn) {
    fn.once = true;
    this.startFns.push(fn);
  }

  onStart(fn) {
    this.startFns.push(fn);
  }
}

export default Reel;
